import React from "react";
import './navbar.scss';
import banner from './banner.png';

function Navbar(){
return(
<div>
<div class="banner"></div>
<div class="container">
   
        <div className="navbar">
            
            <a href="/">HOME</a>

            <a href="/outbad">ABOUT</a>
            
            <a href="/heroes">HEROES</a>
            
            <a href="/thirteen">CHAPTER 13</a>

            <a href="/outbad">OUT BAD</a>


           

        </div>

        
</div>


</div>
)
};

export default Navbar;