import React from "react";
import './luis.scss';

function Luis(){
    return(
       
    <div className="lp">

        <p>LUIS</p>
    Luis Hernandez AKA "Pinche Guason" A rat hidng in wolves clothing is from Ensenada Mexico where he 
    has a tattoo studio. This might seem like a freak accident with the Mongols Mc however quick
    research will show you this is not once you discover the last two Club Presidents "Lil Dave" and 
    "Doc Cavazos" who you can read about on there profiles pages were both informants. If your thinking 
    of involving yourself with these people you must understand the risks you are taking. Guasson can 
    generally be found at his tattoo shop whch is exhibited below or his mothers house also exhibited below.
    Of all the peopl in the motorcycle club that owed me the respect of telling me the FBI was pursing me 
    Guasson was one of these. Take note if you involve yourself with people like this you might find 
    yourselves next to assholes like this intimidating other bike clubs where thing may go dirty fast 
    and put for freedom in jepordy. At the same time people like Guasson who unsiprisingly was an ex cop 
    in Ensenada MX will be the first to take a check from the FEDS to cooperate since it pays more than 
    a failing Tattoo studio. Snitching on me was not Guassons craziest crime after the murder of his friend 
    he cooperated with the FBI to have two alleged associates of "EL MAYO ZAMBADA" arrested which is exhibited below
    more info on these guys can be found below. The names of these two are "EL RAYDER" AND "EL MARCOS" both 
    Guasson and Jorge worked diligently to try and locate these two men on the plaza gleaning information for 
    the United States government. They did this because contrary to the belief these guys are connected or 
    somehow how power in Mexico is a lie the cartels and normal Mexican citizens actually have the power and if it 
    comes down to a dispute Mongols will be dick slaped from the plaza. I know this from living with them in Mexico.
    Conversly people like Rene Arzate "La Rana" the regional boss for Sinaloa in Mexico and "El Mayo Zambada" actually
    protect innocent people in Mexico so for this reason they would never respect the actions of the Mongols. If
    a mongol commits a transgression like Guassons friend Kiko "Luis Javier Padilla" did robbing a minor sellng 
    bread the article exhibited below they will be just like Kiko was as a sat down beside him begging and crying
    to the people that matter from Zambada for his life. After this event he was threatened and explained that if 
    there are no second chances and the abuse of the community willl result in the life of any Mongol that trangresses 
    this line. Below I have included an article that displays the results of Mexican criminals killing an American 
    which results in the Sinaloa cartel doing what the do best and protecting the people. The sinaloa cartel in this 
    case helped locate the perpetrators of the crime with there vast network of "Halcalons" on the plaza and bought them to justice.
    If your a young person and awed by the facad of power these guys have and intend to assume the risks of associaton its
    important to understand this is a myth they have none and one on one they are pussies. I have extended and offer to 
    both Guasson and Jorge to meet me in "El Mayos Zambada's" territory in Culican in which I will fight them
    both consecutively as the hero "SEAN GANNON" RIP from Boston Police Department did with Imbo slice and beat his ass.
    Mongols claim to not back down I guess soon we will see this most likely because if they pull a funny move in Culican Zambada 
    will exterminate any Mongol found they will decline the offer. God willing they will let there balls drop and accept the offer.
    Mongols seek power through numbers and when left alone they have no back up as I saw many times with KIKO And JORGE.
    These events can be read about in there profiles. Some of you may believe this club will help you with women
    well guess what Guasson lost his bitch obviously she was tired of being with a fucking fraud who couldnt
    decide if he wanted to be a biker or a fucking cop. Hopefully this gives you a glimpse of the Mongols life andan understanding
    of the lack of power they really have. The people with the real brains game and balls are actually the Special Agents who bend these guys
    over on the regular as can be viewed in the history section. unsiprisingly none of these agents ever get touched 
    after getting some mongol pussy. Nor has either club president who has turned FED "LIL DAVE" CAN BE SEEN ON HIS profiles
    displaying the power dynamic I ame attempting to exhibit here when he is seen crying like a weak bitch that his real
    daddy Special Agent John Siccone will be retiring and he will have no protection. Ask yourself how Agent Siccone can 
    protect a mongol? The answer is easy through fine cordination and the creation of a strong network of influetial informants in the club.
    Through this the real thugs like Agent Siccone control the upper echelon of power and politics in the club controlling who
    gets voted in and what actions are taken. Do you think it was chance this video was made? No Ciccone decided to burn "Lil Dave" to put a 
    new more controlled leader into place. The same story will repeat many years from now. What it highlights furthermore
    is Mongols obviously can't keep there bitch as she left him. Wanna be a thug learn shit run shit and controls gang territory?
    The answer is easy join the FBI or ATF links posted below.


    Guassons personal information posted below.

        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>
    </div>
    );
};

export default Luis;