import React from "react";
import './jorge.scss';

function Jorge(){
    return(
       
    <div className="jp">

        <p>JORGE</p>

    When it comes to Jorge Bustos we invite an interesting an painstaking process in Mexico I was with this man almost every second 
    of the day I know his family and his entire life history past and present. Jorges younger years were spent 
    doing what he does best as an adult and diavowing his mother and familys wished and his privledge of being 
    in the united states where he live in San Jose California and decided gang banging was the best avenue of 
    approach to success this resulted in jorged being deported from the us and obviously if jorge was actually 
    worth a fuck in the streets his big homies that were super "ON" in the 90's did not want to pay someone to traffic 
    him back into the united states to go back to work. The topic of Jorge could be summed up very simple
    Jorge has three amazing children gow willing who will aspire to be something better than there father and a Beautiful wife with amazing pussy.
    Seems like enough to keep any man happy right? Apparently not as this was not enough for Jorge how did this sntch become a Mongol? You're probably
    thinking well defended territory or pulled someother heroic act but this is not the case this fucking reject. Gave Kiko at the time the president for the
    entire club of mexico his refridgerator for the club house and basically starved his family to death leaving them without food
    or even a way to cool the food in mexico. At one time Jorge owned a tortillaria which failed ultimatly. This is where Jorges 
    story begins and we can see the treachary bedhind this cowardly shit bag. Jorge at this time did what Jorge does best
    and decided to abuse Beautful vivianna like he always has with intimidation and because otherwise a mongol can't keep there bitch. 
    vivianna was working at the tortillaria where jorge found his next victim viviannas best friend a sweet and 
    niave young woman only looking to survive and beliving the lies of jorge that him and vivi had broken up. Jorge fabricated
    lies about this gaining simpathy from Carla Contreras at the same time impressing her with a dodge challenger he owned and a fake
    display of succes when however he didnt even have a fucking title for the car. He continued to lie to Carla who cried to me many times
    asking me if he was still with vivi to which I never lied to her and told her that he was still with her and using her for her beauty and kindness.
    This left Carla in an emotiannaly unstable state to make things worse Jorge then got her pregnant still projecting the same
    story that he split with vivi. At this point vivi who wanted desperatly to keep her childrens home together with no option
    but to sustain the abuse and work at the tortillaria while jorge went right next door to the tortillaria and continued the affair with carla. below is a dated facebook post from carlas facebook 
    account exibiting this accusation for everyone to see and carlas facebok info for you to see personally. It's important to not over look poor
    vivianna who is stuck in this abusive relationship with no way out. Strangely enough Jorges family worked dilligently against me with the fabricated
    as i would expect from any "99% NORMAL CITIZEN". The problem I have with this is they somehow over look the need to help vivianna financially so she can escape the abuse of Jorge.
    and support her through the process of restoring dignity to her life and they fail to admonish jorge for abusing her. And speaking from expirience Jorges mother and Jazmin his niece 
    are not the type of women to support this but I guess since Jorge is family they are happy to oppress another woman. Below I have psted a link to donate to abused women. For this 
    I will post jorges entire family contact list for any reader to call and ask them anything you would like. And furthermore let this be lesson for jorge 
    and his rat shit yee aint safe now the whole fucking club is going to have to live with his actions. The only family contact who is excluded is his brother Adam who has cerabal paulsy and is in a wheel chair Adam 
    is an amazing person independent lives on his own and handles his life affairs himself he is a person who has shown great courage in the face of integrity and does not deserve to have to be part of Jorges BS. Interestingly enough 
    his family can semi attest that one day while adam disabled and not being able to exit a car that Jorge was driving when an operativo of feds and the military came through the neighborhood 
    jorge decided to put his life in jepordy and leave his brother in a car in a position of respoisability for with things you shouldnt. This was a clear display of
    jorges cowardry seeing as he had no problem putting his diabled brother in prison in mexico so he could escape the consequences. Prase god think went well this day for Adam or adam would have been a victim 
    of Jorges abusive nature. For this I have included a website to donate to people with cerbral paulsy below and on the main page. The story doesnt end here with jorge to display 
    his integrity even more in the photo below I have highlighted a young man whos father was killed in mexico for selling drugs without permission which
    is nessacary in mexico without permission you will 100% be killed and are not deemed innocent by any cartel because you have became part of the criminal
    dealings on the plaza. The child hangs out with the children at Carlas house because her and her family try to provide
    a safe enviroment for the neighborhood children. Unbeknowst to carla and her family which would have become infuriated 
    with his actions Jorge decided to assist the kid who was left struggling without food and the items needed to 
    survive by bringing the child into the same life that killed his father Jorge than connected him and told him to begin selling for jorge. 
    What better could be expected from a man that robbed a child selling bread infront of a convient store in mexico 
    which the article can be seen in a link below. he along with Kiko the president at the club at the time were desperatly
    justice not by the police but once again by "El Mayo Zambadas" people with the sinaloa cartel when the explained
    a repeat action resolves in death no second warnings at which point Jorge returned home to his daughter and kids crying 
    and terrifying them that there father would be killed soon probably as any coward would do dragging the children into the drama 
    bringing emotional pain to them. Kiko was later killed potentialy by Zambadas people but this is unknown. If this was the 
    case Zambada and the Regional boss La Rana were only doing what to do best protecting the community in there territory. It
    is important to understand you would think someone that went through these events and many more with you would not snitch on you
    but Jorge is a broke piece of shit so he turned informant to make money. On the discussion of power you would presume 
    the Mongols have let me show you two displays of the power dynamic so you can see lack the lack thereof and the 
    lack of mongols to support other mongols during crises. to understand this it is important to understand the 
    place of the sinaloa cartel in the safe keeping of the community. Crimes like robbery extortion and abuse of people
    that are not involved in the drug trade shall not an will not be touched. Otherwise justice will be served by the gunmen of sinaloa.
    One day jorges son who you would imagine people being scared to abuse was robbed of his bicycle he was only 15 years old a man approached him infront of 
    the convient store and snatched his bike throwing him off of it and telling him "Calle Say" which in spanish means whut the fuck up. In mexico with these types of issues the
    police are genrally useless to resolve this and you resolve it yourself or you tell a local Plaza connection IE drug dealer 
    and than the people of the sinaloa cartel will find the person and set them up in a sting like the police and if they take the bate 
    they kill them on the spot no questions asked and no court date. contrary to popular belief  they actually test the situation 
    and verify this is happening with all offenses before taking action. The day this happened jorge was left defenseless I at the time was with my girlfriend and he called
    me saying hey come to me me fast someone just took the kids bicycle I immediatly responded and here is where you see through the lies 
    of the mongol brotherhood. Did anyone else respond? The answer is no he was left on his own and the club did what they do when shit gets hot.
    at this point me and him and the kids go to find the man that robbed the kid searching all the neighborshoods to serve mexican style justice take the bike back fuck them up and take everything 
    this drug addict has as punishment so he can learn his lesson which is acceptable in mexico in cases like this when 
    the person is a drug addict however do not take drug from anyone. After riding around and not finding the guy we eventually found out what house the 
    guy visits in the neighborhood a day later I arrive at the house alone to serve justice at which time the drug addict was not there. Seems like the 
    kind of act that mongols would respect and adore no this is not the truth. The rule of the game with these types outside of a few alcohol liquid courage fueled events 
    is self preservation. To display the power dyanic with them further in enough event someone who knew Jorge was with the mongols decided that 
    they needed his car more than he did and caught poor vivanna and his daughter who was maybe 10 years old 
    lacking on a back street and held a gun to the head of his daughter and threatened to kill her if her mother did not 
    give up the car. At which point she gave up the car and it was evetually wrecked while they tried to escape and took of running on foot and Jorge retrieved the car.
    This however is a perfect demonstration of the lack of power dynamic with the mongols mc and it's lack of existance. Other notable events to display this 
    are a run of the mill drug addict in mexico stealing viviannas phone in this event the drug addict walks into the tortillaria and steals the phone
    in this case no one from the club again would back jorge up in serving street justice but me. We caught the guy and solved the issue but yet again his brother were not there.
    On the lack of unity in the club another brother from carlos was delivering tortillas for jorge carlos himself I would have to delcare 
    as a decent family man I never gave him the chance to snitch but he would have I am sure. With this said carlos was delivering tortillas for jorge 
    for basically peanuts 1200 pesos a week because jorge was fucking him around this equals about 60$ per week. To make this situation worse carlos wrecked his car in the process of this for this job it's jorges 
    duty to provide the car which he failed to do but carlos using his own car wrecked and as a result went to jorge for money to repair the carl
    at this point jorge rebuffed carlos and told him he would not pay it's important to understand in mexico this would cost no more than 2000 pesos maybe 100$ for the repair
    this caused tension between the two with carlos refusnig to talk to jorge for a period of time after this. When jorge wrecked his own car it was me 
    not his club brothers who gave him a car. otherwise he could not have delivered his tortillas without this. This happened once more when I gave jorge a second car
    having sympathy that his children needed to get around and for vivianna. this event turned into a real circus 
    as it came directly after a passive robbery attempt of the car from me by Kiko and a Southsider who left kiko the mongol club president swimming in
    his babies nightly since he kept kikos wife on his dick, This even you can read about in Kikos profile which for someone 
    wishing to join the mongolsmc s the best profile to read to understand what the upper echelon of the mongols is like 
    and the lack of power and trust they have it is non existant. As you can see from the brief story of jorge the motorcycle
    club provides nothing but liability to you in the event your at an alcohol liquid courage fueled event and 
    needing to handle a territory or club disput or let's say your in mexico and attack an american with the club
    you will quickly find yourself on the long arm of sinaloa cartels justice and Maya Zambadas people as exhibited in the article below.
    As I have stated in other profiles the people who are on the up side of the power dynamic in the streets and gangs are actually the ATF and FBI.  Hopefully this gives you a glimpse of the Mongols life andan understanding
    of the lack of power they really have. The people with the real brains game and balls are actually the Special Agents who bend these guys
    over on the regular as can be viewed in the history section. unsiprisingly none of these agents ever get touched 
    after getting some mongol pussy. Nor has either club president who has turned FED "LIL DAVE" CAN BE SEEN ON HIS profiles
    displaying the power dynamic I ame attempting to exhibit here when he is seen crying like a weak bitch that his real
    daddy Special Agent John Siccone will be retiring and he will have no protection. Ask yourself how Agent Siccone can 
    protect a mongol? The answer is easy through fine cordination and the creation of a strong network of influetial informants in the club.
    Through this the real thugs like Agent Siccone control the upper echelon of power and politics in the club controlling who
    gets voted in and what actions are taken. Do you think it was chance this video was made? No Ciccone decided to burn "Lil Dave" to put a 
    new more controlled leader into place. The same story will repeat many years from now. What it highlights furthermore
    is Mongols obviously can't keep there bitch as she left him. Wanna be a thug learn shit run shit and controls gang territory?
    The answer is easy join the FBI or ATF links posted below.


    A NOTE TO JORGE FROM ME: "You spend your life abusing women I ask you to not act like one yourself and meet me in Culican to fight
    otherwise your club will never get a chance at regaining this domain name I don't care if you are kicked out bad it does not happen until you and guasson fight me 
    you will have a limited time frame to respond to my request to fight if you fail to do so than the club never regains this domain name and it 
    will remain a site dedicated to victims of domestic violence ceebral paulsy and more IMPORTANTLY POLICE RECRUITING because either way the mongols recruit police as I have shown here.
    I will fight both you and guasson back to back in culican in Mayos territory so if you decide to play games his people can deal with you which wouldnt be 
    bright seeing as how I have proved in this article you and guasson snitched on el rayder and el marcos in ensenada both his associates 
    the offer is 3 ROUNDS 2 MINUNTES BACK TO BACK YOU AND GUASSON I dont give a fuck who goes first if you both can't defeat me the club can fuck off on the offer
    and the guilt lays on your backs the event will be live streamed and the winner gets rights to the final video to use however they see fit as with police officer sean GANNON
    when he fought kimbo slice."
    
    
    

    
    

        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>
    </div>
    );
};

export default Jorge;