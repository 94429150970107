import React from "react";
import './heroes.scss';
import Banner from "./banner.png";
import Mommy from "./mommy.png";
import Nuevo from "./villa.jpg";
import Luna from "./luna.jpg";
import Fbi from "./fbi.png";
import Atf from "./atf.png";
import Glaze from "./glaze.png";
import Sean from "./sean.png";
import Garza from "./garza.png";
import Diamond from "./diamond.png";



import { FireExtinguisher } from "@mui/icons-material";



function Heroes(){
    return(
    <div className="hpbg">

<div class="hp-grid-container">
    <div class="hp-grid-item1"> 
        <div className="hp-card"> 
        <a href="./mommy">
         <div className="hp-card-image">
                <img src={Mommy} width="275px" height="300px"></img></div>
                </a>
                <p>LAPD COMMANDER</p>
                <p>TAKES OUT MORE DRUG TRAFFICKERS THAN MONGOL SNITCHES</p>
                <a href="./mommy">Lillian Carranza</a>
                <div><a href="https://www.joinlapd.com">JOIN LAPD!</a></div>
        </div> 
    </div>

    <div class="hp-grid-item2"> 
        <div className="hp-card">
        <a href="./cic">
            <div className="hp-card-image">
                <img src={Atf} width="275px" height="300px"></img></div>
        </a>
                <p>Profile of John Ciccone: Champion of Justice and ATF Agent</p>
                <p>ATF HANDLER / PIMP OF SNITCH & CLUB PRES "LIL DAVE" </p>
                <a href="./cic">John Ciccone</a>
                <div><a href="https://www.atf.gov/careers/becoming-special-agent">JOIN ATF!</a></div>
        </div>
    </div>

    <div class="hp-grid-item3"> 
        <div className="hp-card">
        <a href="./garza">     
            <div className="hp-card-image">
                <img src={Garza} width="275px" height="300px"></img></div>
            </a>
                <p>LAPD OFFICER</p>
                <p>AFTER THIS PICTURE JORGE TEXTED ME. FED SHIT!</p>
                <a href="./garza">GARZA</a>
                <div><a href="https://www.joinlapd.com">JOIN LAPD!</a></div>
        </div>
    </div>

    <div class="hp-grid-item4"> 
        <div className="hp-card">
        <a href="./nuevo">
            <div className="hp-card-image">
                <img src={Nuevo} width="275px" height="300px"></img></div>
        </a>
                <p>LA COUNTY SHERIFF (PREV)</p>
                <p>Former Sheriff Alex Villanueva: Leadership and Legacy in Law Enforcement</p>
                <a href="./nuevo">Villanueva</a>
                <div><a href="https://lasd.org/joinlasd/">JOIN LASD!</a></div>
        </div>
    </div>

    <div class="hp-grid-item5"> 
        <div className="hp-card">
        <a href="./glaze">
            <div className="hp-card-image">
                <img src={Glaze} width="275px" height="300px"></img></div>
        </a>
                <p>Profile of Andy Glaze: Los Angeles Fire Dept COMMANDER and Ultra Marathon Runner</p>
                <p>RUNS 20+ MILES DAY & 250 MILE MARATHONS</p>
                <a href="./glaze">Andrew Glaze LAFD Commander</a>
                <div><a href="https://www.joinlafd.org">JOIN LAFD!</a></div>
        </div>
    </div>

    <div class="hp-grid-item6"> 
        <div className="hp-card">
        <a href="./luna">
            <div className="hp-card-image">
                <img src={Luna} width="275px" height="300px"></img></div>
        </a>
                <p>SHERRIF LASD</p>
                <p>CURRENT SHERRIFF OF LASD! SWAT LEADER (PREV)</p>
                <a href="./luna">Sheriff Robert G. Luna</a>
                <div><a href="https://lasd.org/joinlasd/">JOIN LASD!</a></div>
        </div>
    </div>

    <div class="hp-grid-item7"> 
        <div className="hp-card">
        <a href="./laura">
            <div className="hp-card-image">
                <img src={Fbi} width="275px" height="300px"></img></div>
        </a>
                <p>Profile of FBI Special Agent Laura Schwartzenberger: A Tribute to Service and Sacrifice</p>
                <p>(FBI) AGENT RIP HERO</p>
                <a href="./laura">Laura Schwartzenberger</a>
                <div><a href="https://fbijobs.gov">JOIN FBI!</a></div>
        </div>
    </div>

    <div class="hp-grid-item8"> 
        <div className="hp-card">
        <a href="https://lasd.org/joinlasd/">
            <div className="hp-card-image">
                <img src={Nuevo} width="275px" height="300px"></img></div>
        </a>
                <p>LA COUNTY SHERIFF (PREV)</p>
                <p>TAKES OUT MORE DRUG TRAFFICKERS THAN MONGOL SNITCHES</p>
                <div><a href="https://lasd.org/joinlasd/">JOIN LASD!</a></div>
        </div>
    </div>


    <div class="hp-grid-item9"> 
        <div className="hp-card">    
        <a href="./diamond">
            <div className="hp-card-image">
                <img src={Diamond} width="275px" height="300px"></img></div>
        </a>
                <p>POMONA POLICE DEPT KIA BY MONGOL</p>
                <p>Officer Shaun Diamond: A Legacy of Bravery and Sacrifice</p>
                <a href="./diamond">Shaun Diamond</a>
                <div><a href="https://www.pomonaca.gov/government/departments/police-department/join-pomona-pd">JOIN PDP!</a></div>
        </div>
    </div>



</div>

        
        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>

    <div className="btmblock"></div>

    </div>
    );
};

export default Heroes;