import React from "react";
import './kiko.scss';

function Kiko(){
    return(
       
    <div className="kp">

        <p>KIKO</p>
            
            stripper pole






        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>



    </div>
    );
};

export default Kiko;