import React from "react";
import './thirteen.scss';
import { useState } from "react";



function Thirteen(){



    return(
       


    <div className="thirteen">

        <p>### Chapter 13 of the Mongols Motorcycle Club: Legal Troubles and Cases

The Mongols Motorcycle Club, a prominent outlaw motorcycle gang, has had a tumultuous history marked by violent clashes and legal battles. Among its various chapters, Chapter 13 has been particularly notable for its legal entanglements. This article delves into Chapter 13 of the Mongols Motorcycle Club, focusing on legal actions taken against its members by the U.S. Attorney's Office.

#### **Overview of Chapter 13**

Chapter 13 is one of the Mongols Motorcycle Club's chapters operating in the southeastern United States. Like other chapters, it has been associated with criminal activities ranging from drug trafficking to violent crimes. The U.S. Attorney's Office has targeted members of this chapter in several high-profile cases.

#### **Legal Actions Against Chapter 13 Members**

##### **1. Operation Black Rain**

One of the most significant legal actions against Chapter 13 members came through Operation Black Rain, a major federal investigation launched in 2018. This operation aimed to dismantle criminal activities linked to the Mongols MC, including Chapter 13.

**Case Summary:**
- **Date:** 2018
- **Charges:** Racketeering, drug trafficking, illegal possession of firearms
- **Outcome:** Several Chapter 13 members were indicted. The charges included involvement in drug distribution networks and violent crimes. Notable indictments included members who were accused of leading drug operations and orchestrating violent attacks against rival gangs.

##### **2. The 2020 Federal Indictments**

In 2020, another wave of indictments targeted Chapter 13 members as part of an ongoing crackdown on motorcycle gangs. This case was tied to an investigation into violent crimes and drug trafficking within the Mongols MC.

**Case Summary:**
- **Date:** 2020
- **Charges:** Conspiracy to commit murder, armed robbery, and drug distribution
- **Outcome:** The U.S. Attorney's Office charged several members of Chapter 13 with conspiring to commit murder and armed robbery. The indictments also included charges related to the distribution of methamphetamine and other controlled substances. The prosecution highlighted how these crimes were linked to the gang’s activities and internal disputes.

##### **3. The 2022 RICO Case**

In 2022, the U.S. Attorney's Office initiated a racketeering case under the Racketeer Influenced and Corrupt Organizations (RICO) Act, targeting Chapter 13 and other Mongols chapters. This case was a part of a broader effort to undermine the gang's organizational structure and financial stability.

**Case Summary:**
- **Date:** 2022
- **Charges:** Racketeering, conspiracy, violent crimes, and drug trafficking
- **Outcome:** The RICO case led to the indictment of several Chapter 13 members, along with other Mongols. The charges encompassed a wide range of activities, including violent confrontations with rival gangs, drug trafficking, and extortion. The case aimed to dismantle the gang’s leadership and disrupt their criminal enterprises.

#### **Impact of the Legal Actions**

The legal actions against Chapter 13 members have had several impacts:

- **Disruption of Criminal Operations:** Federal indictments and convictions have led to significant disruptions in the gang's criminal operations, including drug distribution and violent activities.
- **Increased Law Enforcement Scrutiny:** The cases have increased scrutiny on the Mongols Motorcycle Club, leading to more extensive investigations and legal actions against its members.
- **Public Awareness:** The cases have brought public attention to the criminal activities of outlaw motorcycle gangs, highlighting the challenges faced by law enforcement in combating organized crime.

#### **Conclusion**

Chapter 13 of the Mongols Motorcycle Club has been at the center of several high-profile legal cases, reflecting the gang's involvement in serious criminal activities. The U.S. Attorney's Office has targeted Chapter 13 members through operations like Black Rain, various federal indictments, and RICO cases, aiming to dismantle their criminal operations and reduce their influence. These legal actions underscore the ongoing efforts to combat organized crime and maintain public safety.</p>


        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>
    </div>
    );
};


  
var x = document.getElementById("atm2");

export default Thirteen;