import React from "react";
import './homepage.scss';
import Snitch from './snitch.png';
import Storyfp from './storyfp.png';
import Baca from './baca.png';
import Background from './background.png';


function Homepage(){
    return(
       
    <div className="hp">


<div className="hpp">

    <h1 className="hh">
        placeholder
    </h1>

    <p>TEXT</p>



</div>

    <div className="fpright">
        <a href="https://tips.fbi.gov/home"><img src={Snitch}></img></a>
        <a href="https://www.bacaworld.org"><img src={Baca}></img></a>
        <img src={Storyfp}></img>
    </div>

        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>
    </div>
    );
};

export default Homepage;
